import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPhone,faEnvelope} from '@fortawesome/free-solid-svg-icons';
import React from 'react'
import PhotoHeader from '../Components/PhotoHeader'
import Form from './Form'
import { Link } from 'react-router-dom';

const Contact = () => {
  return (
    <>
     <PhotoHeader/>
    <h2 style={{bottom:"150px",position:"relative"}} className="text-center fw-bold  ">  Contact Us </h2>
    <p style={{bottom:"150px",position:"relative"}} className='text-center fw-bold fs-4'> You Have Questions, We Have Answers</p>
    <section id='contact-1' className='bg-light'  style={{marginTop:'-120px'}}>
       <div className="container">
        <div className="row py-5">
            <div className="col-lg-4 my-5 mx-auto">
            <div className="card py-5 px-3 border-0  text-center">
              <div className="card-body">
              <a href='tel:+19058220838' className='text-decoration-none text-dark'>
                <h5 className="card-subtitle text-muted"><FontAwesomeIcon icon={faPhone}/></h5>
                <p className="card-title my-2  fs-5   ">Call us</p>
                <p className='card-text fw-bold'><span className='fw-bold'> (905) 822-0838</span></p></a>
                
              </div>
        </div>
            </div>
            <div className="col-lg-4 my-5 mx-auto">
              
            <div className="card py-5 px-3 border-0   text-center" >
              <div className="card-body">
              <Link to={window.location.pathname} className='text-decoration-none'
                  onClick={() => window.location = 'mailto:info@ontarioitsolution.com'}>
                <h5 className="card-subtitle text-muted"><FontAwesomeIcon icon={faEnvelope}/></h5>
                <h6 className="card-title my-2 text-dark fs-5 ">Mail</h6>
                <p className="card-text text-dark fw-bold">info@ontarioitsolution.com </p>
                </Link>
              </div>
        </div>
            </div>
        </div>


        </div>
    </section>
    <section className='my-5'>
        <div className="container">
            <h2 className='fs-1 text-center fw-bold mb-5'>Let's Talk</h2>
            <Form/>
        </div>
    </section>
    
    
    
    </>
  )
}

export default Contact