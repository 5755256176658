import Navbar from './Components/Navbar'
import Footer from './Components/Footer'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Main from './Pages/Main'
import About from './Pages/About';
import Services from './Pages/Services';
import IT from './Pages/Services/IT';
import Modern from './Pages/Services/Modern';
import Consulting from './Pages/Services/Consulting';
import Work from './Pages/Services/Work';
import Cloud from './Pages/Services/Cloud';
import Security from './Pages/Services/Security';
import Service from './Pages/Services/Service';
import Industries from './Pages/Industries';
import Associations from './Pages/Industry/Associations';
import Education from './Pages/Industry/Education';
import Financial from './Pages/Industry/Financial';
import Health from './Pages/Industry/Health';
import Legal from './Pages/Industry/Legal';
import Local from './Pages/Industry/Local';
import Profit from './Pages/Industry/Profit';
import Retail from './Pages/Industry/Retail';
import Contact from './Pages/Contact';
import Privacy from './Pages/Privacy';
import ScrollToTop from "./Pages/ScrollToTop";
function App() {
  return (
    <>
   <Router>
   
    <Navbar/>
    <ScrollToTop />
    <Routes>
      <Route path='/' exact element={<Main/>}/>
      <Route path='/About' exact element={<About/>}/>
      <Route path='/Services' exact element={<Services/>}/>
      <Route path='/Services/IT' exact element={<IT/>}/>
      <Route path='/Services/ModernDesktop' exact element={<Modern/>}/>
      <Route path='/Services/Consulting' exact element={<Consulting/>}/>
      <Route path='/Services/Cloud' exact element={<Cloud/>}/>
      <Route path='/Services/FullService' exact element={<Service/>}/>
      <Route path='/Services/Security' exact element={<Security/>}/>
      <Route path='/Services/WorkFromHome' exact element={<Work/>}/>
      <Route path='/Industries' exact element={<Industries/>}/>
      <Route path='/Industries/Associations' exact element={<Associations/>}/>
      <Route path='/Industries/Education' exact element={<Education/>}/>
      <Route path='/Industries/Financial' exact element={<Financial/>}/>
      <Route path='/Industries/Health' exact element={<Health/>}/>
      <Route path='/Industries/Legal' exact element={<Legal/>}/>
      <Route path='/Industries/Local' exact element={<Local/>}/>
      <Route path='/Industries/Profit' exact element={<Profit/>}/>
      <Route path='/Industries/Retail' exact element={<Retail/>}/>
      <Route path='/Contact' exact element={<Contact/>}/>
      <Route path='/PrivacyPolicy' exact element={<Privacy/>}/>
     
    </Routes>
    <Footer/>
  </Router>
    </>
  );
}

export default App;
