import React from 'react'
import PhotoHeader from '../../Components/PhotoHeader'
import work from '../../Images/Services/work.png'
import Contact from '../Industry/contact'
import {faRoad, faPhoneVolume, faCloud, faHandshakeAngle } from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const Work = () => {
  return (
    <>
    <PhotoHeader/>
    <h2 style={{bottom:"150px",position:"relative"}} className="text-center fw-bold  "> WORK FROM HOME </h2>
    <p style={{bottom:"150px",position:"relative"}} className='text-center fw-bold fs-4'>Staff Can Work from Home Securely and Reliably</p>
    <section id='IT1'  style={{marginTop:'-100px'}}>
    <div className="container">
    <div className="row my-5">
        <div className="col-lg-6">
            <img src={work} alt="work" className='img-fluid' />
        </div>
        <div className="col-lg-6">
            <h5 className='text-warning mt-4'>Work From Home</h5>
            <h4 className='fs-1 my-3'>We can help you securely & reliably connect your Staff to your Organization’s I.T. Services.</h4>
            <p className='fw-bold fs-1 text-center'>TO WORK FROM HOME!</p>
            <p className='my-3'> ONTARIO IT SOLUTION provides various Work From Home Options that can help your Organization provide your Staff the Access and Flexibility they need.</p>
            <p className='my-5 fw-bold text-muted'>We Make Working From Home…</p>
            <div className="text-muted text-end">
            <p ><span className='fw-bold  pe-2'>EASY</span> To Use</p>
            <p><span className='fw-bold  pe-2'>RELIABLE</span> To Use</p>
            <p><span className='fw-bold pe-2'>SAFE</span> To Use</p>
            <p><span className='fw-bold  pe-2'>AFFORDABLE</span> To Use</p>
            </div>
        </div>
    </div>
    </div>
    </section>
    <section id='IT2'>
    <div className="bg-light">
        <div className="container">
    <div className="row  py-5">
        <div className="col-lg-6">
            <div className='my-5'>
            <h5 className="card-title mb-4 text-warning"><FontAwesomeIcon icon={faRoad} size='2x'/></h5>
                <h5 className='fw-bold fs-3'>Secure Remote Access to the Office Computer</h5>
                <p className='text-muted'>Allowing your Staff to use their Personal Devices at Home to securely remote control their Office Computer, as if they were sitting at their Office Desk.</p>
            </div>
            <div className='my-5'>
            <h5 className="card-title mb-4 text-warning"><FontAwesomeIcon icon={faPhoneVolume} size='2x'/></h5>
                <h5 className='fw-bold fs-3'>Secure Communications between Staff</h5>
                <p className='text-muted'>Your Staff can continue to securely communicate with each other and your Customers from Home, using their Personal Devices.</p>
           
            </div>
        </div>
        <div className="col-lg-6">
        <div className='my-5'>
        <h5 className="card-title mb-4 text-warning"><FontAwesomeIcon icon={faCloud} size='2x'/></h5>
                <h5 className='fw-bold fs-3'>Secure Access to Office 365 From Home Computer</h5>
                <p className='text-muted'>The ability for Staff to access all your Office 365 Services securely from their Home Computer, keeping everything on the Cloud and not on their local Home Computer.</p>
            </div>
            <div className='my-5'>
            <h5 className="card-title mb-4 text-warning"><FontAwesomeIcon icon={faHandshakeAngle} size='2x'/></h5>
                <h5 className='fw-bold fs-3'>I.T. Support for your Remote Staff</h5>
                <p className='text-muted'>Be confident knowing that your Staff are supported remotely by a Highly Experienced & Trained Technical Team. When they need Help, we are there.</p>
           
            </div>
        </div>
    </div>
    </div>
    </div>
    </section>
   <Contact/>
    </>
  )
}

export default Work