import React from 'react'
import { Link } from 'react-router-dom'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { faList, faBuilding, faSuitcase,  faSitemap, faDesktop, faHandshakeSimpleSlash, faCloud, faServer, faShield} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import sl1 from '../Images/Slider/sl1.jpg'
import sl2 from '../Images/Slider/sl2.jpg'
import sl3 from '../Images/Slider/sl3.jpg'
import sl4 from '../Images/Slider/sl4.jpg'
import sl5 from '../Images/Slider/sl5.jpg'



const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1024 },
    items: 1,

  },
  desktop: {
    breakpoint: { max: 1024, min: 768 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 768, min: 640 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 640, min: 0 },
    items: 1,
    showDots: false,
  },
};

const Main = () => {
  return (
    <>
      <Carousel infinite responsive={responsive}  autoPlay={true}  autoPlaySpeed={5000} className='mt-6'>
      
      <div>
        <img src={sl1} className="d-block w-100 img-fluid sl" style={{maxHeight:'650px', minHeight:'284px'}}  alt="..."/>
        <div className="carousel-caption bg-slider ">
          <h2 className="fs-1 text-warning ">Hello! Welcome to</h2>
          <h2>ONTARIO IT SOLUTION</h2>
          <p className='fs-5'>We Provide Your Small & Medium Size Organization with An All-in-one I.T Department.</p>
          <Link to='/About' className="btn btn-warning  me-3 mt-2">Learn More</Link>
        </div>
      </div>
      <div>
        <img src={sl2} className="d-block w-100 img-fluid sl" style={{maxHeight:'650px', minHeight:'284px'}}  alt="..."/>
        <div className="carousel-caption  bg-slider">
          <h2 className="fs-1 text-warning ">ARE YOU UNHAPPY WITH YOUR EXISTING I.T. SUPPORT?</h2>
          <p className='fs-5'>Let Us Help!</p>
          <Link to='/Contact' className="btn btn-warning  me-3 mt-2">Are We A Good Fit?</Link>
        
        </div>
        </div>
      <div>
        <img src={sl3} className="d-block w-100 img-fluid sl" style={{maxHeight:'650px', minHeight:'284px'}}  alt="..."/>
        <div className="carousel-caption  bg-slider ">
          <h2 className="fs-1 text-warning">HOW SAFE IS YOUR BUSINESS FROM A CYBER ATTACK? </h2>
          <p className='fs-5'>We  Work With Your Organization to Protect You From Cyber Crime</p>
          <Link to='/Contact' className="btn btn-warning  me-3 mt-2">Let Us Show You How.</Link>
      
        </div>
        </div>
      <div>
        <img src={sl4} className="d-block w-100 img-fluid sl" style={{maxHeight:'650px', minHeight:'284px'}}  alt="..."/>
        <div className="carousel-caption  bg-slider ">
          <h2 className="fs-1 text-warning">GET I.T. PIECE OF MIND IN 2022!</h2>
          <p className='fs-5'>Don't Let Technology Limit Your Organization's Succes</p>
          <Link to='/Contact' className="btn btn-warning  me-3 mt-2">Let's Talk</Link>
        </div>
      </div>
      <div>
        <img src={sl5} className="d-block w-100 img-fluid sl" style={{maxHeight:'650px', minHeight:'284px'}}  alt="..."/>
        <div className="carousel-caption  bg-slider ">
          <h2 className="fs-1 text-warning">WHY CLIENTS CHOOSE US?</h2>
          <p className='fs-5'>Clients Sized 5-500 Staff Partner With ONTARIO IT SOLUTION Because They Need Experienced I.T. Professionals To Deliver I.T 'Peace Of Mind'. Our Focus Is On Real Support.For Real People. </p>
          <Link to='/Contact' className="btn btn-warning  me-3 mt-2">Learn More</Link>
        </div>
      </div>
      
    
    
    
  
      </Carousel>

      <section id='main-card' className='bg-light'>
       <div className='container'>
        <div className='row  py-5 mx-auto'>
          <div className="col-lg-4 mt-4">
            <div className="card border-0 shadow-lg w-100 h-100 p-3 text-center " style={{width: "18rem"}}>
              <div className="card-body">
                <h5 className="card-title text-warning mb-4"><FontAwesomeIcon icon={faBuilding} size='2x'/></h5>
                <h6 className="card-subtitle mb-2 ">About ONTARIO IT SOLUTION</h6>
                <p className="card-text text-muted">We Provide Your Organization with An All-In-One I.T. Department.</p>
                <div className='mt-5 pt-3' ><Link to='/About' className="btn btn-warning  me-3 ">Details</Link></div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 mt-4">
          <div className="card border-0 shadow-lg w-100 h-100 p-3 text-center" style={{width: "18rem"}}>
              <div className="card-body">
              <h5 className="card-title text-warning mb-4"><FontAwesomeIcon icon={faList} size='2x'/></h5>
                <h6 className="card-subtitle mb-2 ">Our Services</h6>
                <p className="card-text text-muted">We Provide I.T. Support, Cloud Services, I.T. Security and I.T. Advisory Services. We ARE Your I.T. Department.</p>
                <div ><Link to='/Services' className="btn btn-warning me-3 ">Details</Link></div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 mt-4">
          <div className="card  border-0 shadow-lg  w-100 h-100 p-3  text-center" style={{width: "18rem"}}>
              <div className="card-body">
              <h5 className="card-title text-warning mb-4"><FontAwesomeIcon icon={faSuitcase} size='2x'/></h5>
                <h6 className="card-subtitle mb-2 ">Our Industries</h6>
                <p className="card-text text-muted">Over the Years, We've Been Able to Lend Our Unique Blend of Expertise to Organizations in A Wide Range of Different Industries.</p>
                <Link to='/Industries' className="btn btn-warning  me-3 mt-auto">Details</Link>
              </div>
            </div>
          </div>
        </div>
        <div className='row py-5 '>
          <div className="col-lg-6 mt-4 text-center ">
            <h1 className='text-warning'>ONTARIO IT SOLUTION</h1>
            <h4>WE MAKE BUSINESS TECHNOLOGY…</h4>
          </div>
          <div className="col-lg-4 mx-auto mt-4 text-center">
            <h4 ><span className='fw-bold fs-2 pe-2'>EASY</span> To Use</h4>
            <h4><span className='fw-bold fs-2 pe-2'>RELIABLE</span> To Use</h4>
            <h4><span className='fw-bold fs-2 pe-2'>SAFE</span> To Use</h4>
            <h4><span className='fw-bold fs-2 pe-2'>AFFORDABLE</span> To Use</h4>
          </div> 
        </div>
        </div>
      </section>
      <section id='main-services' className='container pb-5'>
        <div className="row mt-5">
            <div className="col-lg-4 mx-auto">
              <h2 className='fw-bold fs-1 text-center'>Services</h2>
              <p className='text-center text-muted'>You: Focus on Business  —  We: Focus on Your I.T.</p>
            </div>
        </div>
        <div className='row mt-5'>
          <div className="col-lg-5 text-center">
            <h4 className='text-warning fw-bold'>We Are Your I.T. Department</h4>
            <p className='fs-2 fw-bold pt-3'>We Provide I.T. Support, Cloud Services, I.T. Security and I.T. Advisory Services.</p>
            <p className='text-muted fw-bold pt-5'>No Organization Is Too Small To Have Their OWN I.T. Department.</p>
          </div>
          <div className="col-lg-3 ms-auto ">
            <div className="card border-0 mx-auto  text-center " style={{width: "18rem"}}>
            <Link to="/" className='text-decoration-none'>
              <div className="card-body">
                <h5 className="card-title text-warning mb-4"><FontAwesomeIcon icon={faSitemap} size='2x'/></h5>
                <h6 className="card-subtitle mb-2  fw-bold text-dark fs-4">I.T. Support</h6>
              </div>
            </Link>
            </div>
            <div className="card border-0 mx-auto text-center my-3 " style={{width: "18rem"}}>
            <Link to="/" className='text-decoration-none'>
              <div className="card-body">
              <h5 className="card-title text-warning mb-4"><FontAwesomeIcon icon={faDesktop} size='2x'/></h5>
                <h6 className="card-subtitle mb-2  fw-bold text-dark fs-4">Modern Desktop</h6>
              </div>
            </Link>
            </div>
            <div className="card border-0 mx-auto  text-center my-3" style={{width: "18rem"}}>
            <Link to="/" className='text-decoration-none'>
              <div className="card-body">
              <h5 className="card-title text-warning mb-4"><FontAwesomeIcon icon={faHandshakeSimpleSlash} size='2x'/></h5>
                <h6 className="card-subtitle mb-2  fw-bold text-dark fs-4">Consulting</h6>
              </div>
            </Link>
            </div>
          </div>
          <div className="col-lg-3 ">
            <div className="card border-0 mx-auto text-center " style={{width: "18rem"}}>
            <Link to="/" className='text-decoration-none'>
              <div className="card-body">
              <h5 className="card-title text-warning mb-4"><FontAwesomeIcon icon={faCloud} size='2x'/></h5>
                <h6 className="card-subtitle mb-2  fw-bold text-dark fs-4">Cloud</h6>
              </div>
            </Link>
            </div>
            <div className="card border-0 mx-auto text-center " style={{width: "18rem"}}>
            <Link to="/" className='text-decoration-none'>
              <div className="card-body">
              <h5 className="card-title text-warning mb-4"><FontAwesomeIcon icon={faShield} size='2x'/></h5>
                <h6 className="card-subtitle mb-2  fw-bold text-dark fs-4">Security & Business Continuity</h6>
              </div>
            </Link>
            </div>
            <div className="card border-0 mx-auto text-center " style={{width: "18rem"}}>
            <Link to="/" className='text-decoration-none'>
              <div className="card-body">
              <h5 className="card-title text-warning mb-4"><FontAwesomeIcon icon={faServer} size='2x'/></h5>
                <h6 className="card-subtitle mb-2  fw-bold text-dark fs-4">Full Services I.T Departmant</h6>
              </div>
            </Link>
            </div>
          </div>
        </div>
      </section>
      <section className='bg-warning'>
        <div className='container'>
      <div className="row py-5">
        <div className="col-lg-4 text-center">
          <p className='fw-bold fs-3 text-muted'>Let's Talk</p>
          <p className='fw-bold fs-2 '>When You Are Ready, We Are Here.</p>
        </div>
        <div className="col-lg-4 d-flex align-content-center flex-wrap justify-content-center text-center">
          <p className='text-muted my-auto'>We Understand You Are Busy.  We Respect Your Time.  When You Are Ready, We Are Here.</p>
        </div>
        <div className="col-lg-4 d-flex align-content-center flex-wrap justify-content-center">
        <div className=' '>
          <Link to='/Contact' className="btn btn-outline-secondary btn-lg mt-3 text-dark border-3">Contact Us</Link>
        </div>
        </div>
      </div>
      </div>

      </section>
      
    
    </>
  )
}

export default Main