import {React} from 'react'
import { Link } from 'react-router-dom';



function Footer1() {

  return (
    <>
  
    <section id='copyright' className='bg-dark text-white'>
        <div className='container'>
          <div className="row py-4 text-center border-bottom">
            <div className="col-lg-2 ms-auto"><Link to='/' className='text-white text-decoration-none fs-5 '>Home</Link></div>
            <div className="col-lg-2"><Link to='/About' className='text-white text-decoration-none fs-5'>About</Link></div>
            <div className="col-lg-2"><Link to='/Services' className='text-white text-decoration-none fs-5'>Services</Link></div>
            <div className="col-lg-2"><Link to='/Industries' className='text-white text-decoration-none fs-5'>Industries</Link></div>
            <div className="col-lg-2"><Link to='/Contact' className='text-white text-decoration-none fs-5'>Contact</Link></div>
            <div className="col-lg-2 me-auto"><Link to='/PrivacyPolicy' className='text-white text-decoration-none fs-5'>Privacy Policy</Link></div>
          </div>
          <p style={{fontSize:'0.675rem'}} className='text-center pt-4' >1641709 Ontario Limited Operating  As  Ontario IT Solution</p>
          <p className='text-center pb-4 mt-4 mb-0'><b>  Ontario IT Solution</b> - All Rights Reserved.</p> 
        </div>
    </section>
    </>
  )
}

export default Footer1