import React from 'react'
import PhotoHeader from '../../Components/PhotoHeader'
import consulting from '../../Images/Services/consulting.png'
import Contact from '../Industry/contact'
import { faCircleUser, faPhoneVolume, faPeopleGroup, faSchool } from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const Consulting = () => {
  return (
    <>
    <PhotoHeader/>
    <h2 style={{bottom:"100px",position:"relative"}} className="text-center fw-bold  "> CONSULTING </h2>
    <section id='IT1'>
    <div className="container">
    <div className="row my-5">
        <div className="col-lg-6">
            <img src={consulting} alt="consulting" className='img-fluid' />
        </div>
        <div className="col-lg-6">
            <h5 className='text-warning'>Consulting</h5>
            <h4 className='fs-1 my-3'>Projects & Solutions That Meet Your Organization’s Needs</h4>
            <p className='my-3'> ONTARIO IT SOLUTION Provides Experienced And Expertise I.T. Consulting & Projects.  The Quality Expected by Big Business for Your Organization.</p>
            <p className='my-5 fw-bold text-muted'>We Make I.T. Consulting…</p>
            <div className="text-muted text-end">
            <p ><span className='fw-bold  pe-2'>EASY</span> To Use</p>
            <p><span className='fw-bold  pe-2'>RELIABLE</span> To Use</p>
            <p><span className='fw-bold pe-2'>SAFE</span> To Use</p>
            <p><span className='fw-bold  pe-2'>AFFORDABLE</span> To Use</p>
            </div>
        </div>
    </div>
    </div>
    </section>
    <section id='IT2'>
    <div className="bg-light">
        <div className="container">
    <div className="row  py-5">
        <div className="col-lg-6">
            <div className='my-5'>
            <h5 className="card-title mb-4 text-warning"><FontAwesomeIcon icon={faCircleUser} size='2x'/></h5>
                <h5 className='fw-bold fs-3'>CIO Services</h5>
                <p className='text-muted'>The Executive Leadership You Need to Align Your Organization and I.T. Strategies. You Don’t Need to Be Big to Have Your Own Chief Information Officer (CIO). Let Us Show You How.</p>
            </div>
            <div className='my-5'>
            <h5 className="card-title mb-4 text-warning"><FontAwesomeIcon icon={faPhoneVolume} size='2x'/></h5>
                <h5 className='fw-bold fs-3'>Vendor Management</h5>
                <p className='text-muted'>When You Need a “Techie” When Dealing with Your Vendors Who Can You Turn Too? Let Us Translate For You.</p>
           
            </div>
        </div>
        <div className="col-lg-6">
        <div className='my-5 pb-2'>
        <h5 className="card-title mb-4 text-warning"><FontAwesomeIcon icon={faPeopleGroup} size='2x'/></h5>
                <h5 className='fw-bold fs-3'>I.T. Projects</h5>
                <p className='text-muted'>When It Is Time to Execute Your I.T. Direction You Need the Skills to Assess, Design, Test and Build. Your Own I.T. Projects Team. Let Us Show You How.</p>
            </div>
            <div className='my-5'>
            <h5 className="card-title mb-4 text-warning"><FontAwesomeIcon icon={faSchool} size='2x'/></h5>
                <h5 className='fw-bold fs-3'>Training Services</h5>
                <p className='text-muted'>When Your Organization Needs to Update Their Knowledge, Learn How to Be Cyber Aware or Help With Learning Something New, We Can Help.</p>
           
            </div>
        </div>
    </div>
    </div>
    </div>
    </section>
    <Contact/>
    </>
  )
}

export default Consulting