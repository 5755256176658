import React from 'react'
import { Link } from 'react-router-dom'
import PhotoHeader from '../Components/PhotoHeader'
import {  faHandshakeAngle, faComputer, faCloud, faDesktop, faServer, faHome } from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faKeycdn } from '@fortawesome/free-brands-svg-icons';

const Services = () => {
  return (
    <>
    <PhotoHeader/>
    <h2 style={{bottom:"150px",position:"relative"}} className="text-center fw-bold "> About <span className='text-primary'>OUR SERVICES</span></h2>
    <p style={{bottom:"150px",position:"relative"}} className='text-center fw-bold fs-4'>You: Focus on Business — We: Focus on Your I.T.</p>
    <div className="container mb-5">
    <div className="row">
        <div className="col-lg-4 mt-5">
        <div className="card py-5 px-3 border-0 shadow-lg  w-100 h-100 text-center" style={{width: "18rem"}}>
              <div className="card-body">
              <h5 className="card-title mb-4 text-warning"><FontAwesomeIcon icon={faComputer} size='2x'/></h5>
                <h5 className="card-title mb-2 ">I.T Support</h5>
                <p className="card-text text-muted pb-4">Get Your Employees the I.T Support They Need. When They Need It.</p>
                <Link to='/Services/IT' className="btn btn-warning  me-3 mt-5">Details</Link>
              </div>
        </div>
        </div>
        <div className="col-lg-4 mt-5">
        <div className="card py-5 px-3 border-0 shadow-lg  w-100 h-100 text-center" style={{width: "18rem"}}>
              <div className="card-body">
              <h5 className="card-title mb-4 text-warning"><FontAwesomeIcon icon={faCloud} size='2x'/></h5>
                <h5 className="card-title mb-2 ">Cloud</h5>
                <p className="card-text text-muted">Clients Partner With ONTARIO IT SOLUTION To Help Them Take Their Organization to the 'Cloud' Because Our Costumers Love the Fact. We Make Cloud Work!</p>
                <Link to='/Services/Cloud' className="btn btn-warning  me-3 mt-auto">Details</Link>
              </div>
        </div>
        </div>
        <div className="col-lg-4 mt-5">
        <div className="card py-5 px-3 border-0 shadow-lg  w-100 h-100 text-center" style={{width: "18rem"}}>
              <div className="card-body">
              <h5 className="card-title mb-4 text-warning"><FontAwesomeIcon icon={faDesktop} size='2x'/></h5>
                <h5 className="card-title mb-2 ">Modern Desktop</h5>
                <p className="card-text text-muted">The Modern Desktop Is the Use of Any Devices in A Cloud and Mobile World. Access Your Orgnization's Resources Anywhere,Anytime.</p>
                <Link to='/Services/ModernDesktop' className="btn btn-warning  me-3 mt-4">Details</Link>
              </div>
        </div>
        </div>
    </div>
    <div className="row ">
        <div className="col-lg-4 mt-5">
        <div className="card py-5 px-3 border-0 shadow-lg  w-100 h-100 text-center" style={{width: "18rem"}}>
              <div className="card-body">
              <h5 className="card-title mb-4 text-warning"><FontAwesomeIcon icon={faKeycdn} size='2x'/></h5>
                <h5 className="card-title mb-2 ">Security & Business Continuity </h5>
                <p className="card-text text-muted">Helping Your Organization with Backup. Business Continuity and I.T Security Services</p>
                <Link to='/Services/Security' className="btn btn-warning  me-3 mt-5">Details</Link>
              </div>
        </div>
        </div>
        <div className="col-lg-4 mt-5">
        <div className="card py-5 px-3 border-0 shadow-lg  w-100 h-100 text-center" style={{width: "18rem"}}>
              <div className="card-body">
              <h5 className="card-title mb-4 text-warning"><FontAwesomeIcon icon={faHandshakeAngle} size='2x'/></h5>
                <h5 className="card-title mb-2  ">Consulting</h5>
                <p className="card-text text-muted">Get the Benefit of a Qualified I.T Department and Delivering I.T Projects, with Over 100's Of Successful Projects Delivered to Small & Medium Size Organizations.</p>
                <Link to='/Services/Consulting' className="btn btn-warning  me-3 mt-auto">Details</Link>
              </div>
        </div>
        </div>
        <div className="col-lg-4 mt-5">
        <div className="card py-5 px-3 border-0 shadow-lg  w-100 h-100 text-center" style={{width: "18rem"}}>
              <div className="card-body">
              <h5 className="card-title mb-4 text-warning"><FontAwesomeIcon icon={faServer} size='2x'/></h5>
                <h5 className="card-title mb-2  ">Full Services I.T Department</h5>
                <p className="card-text text-muted">The Power of a Big Business I.T Department for Small & Medium Sized Organizations.</p>
                <Link to='/Services/FullService' className="btn btn-warning  me-3 mt-5">Details</Link>
              </div>
        </div>
        </div>
    </div>
    <div className="row">
        <div className="col-lg-4 mt-5">
        <div className="card py-5 px-3 border-0 shadow-lg  w-100 h-100 text-center" style={{width: "18rem"}}>
              <div className="card-body">
              <h5 className="card-title mb-4 text-warning"><FontAwesomeIcon icon={faHome} size='2x'/></h5>
                <h5 className="card-title mb-2 ">Work From Home</h5>
                <p className="card-text text-muted">Give Your Employees the Ability to Work From Home Securely and Reliably</p>
                <Link to='/Services/WorkFromHome' className="btn btn-warning  me-3 mt-4">Details</Link>
              </div>
        </div>
        </div>
       
    </div>
    </div>
    </>
  )
}

export default Services