import React from 'react'
import PhotoHeader from '../../Components/PhotoHeader'
import i1 from '../../Images/Industry/i1.png'
import Contact from './contact'

const Local = () => {
  return (
    <>
    <PhotoHeader/>
    <h2 style={{bottom:"100px",position:"relative"}} className="text-center fw-bold  "> INDUSTRY – LOCAL GOVERNMENT </h2>
    <section id='IT1'>
    <div className="container">
    <div className="row my-5">
        <div className="col-lg-6">
            <img src={i1} alt="i1" className='img-fluid' />
        </div>
        <div className="col-lg-6">
            <h5 className='text-warning mt-4'>Local Government</h5>
            <h4 className='fs-1 my-3'>I.T. Solutions for Local Government That Are a Reliable and Flexible Way to Meet Your Constituents, Council Members, Staff Needs.</h4>
            <p className='my-3'> ONTARIO IT SOLUTION Provides I.T. Services for Local Government. One Size Does Not Fit All! Every Local Government Is Different!</p>
            <p className='my-5 fw-bold text-muted'>We Make I.T. Services for Local Government…</p>
            <div className="text-muted text-end">
            <p ><span className='fw-bold  pe-2'>EASY</span> To Use</p>
            <p><span className='fw-bold  pe-2'>RELIABLE</span> To Use</p>
            <p><span className='fw-bold pe-2'>SAFE</span> To Use</p>
            <p><span className='fw-bold  pe-2'>AFFORDABLE</span> To Use</p>
            </div>
        </div>
    </div>
    </div>
    </section>
    
    <Contact/>
    </>
  )
}

export default Local