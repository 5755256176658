import React from 'react'
import { Link } from 'react-router-dom'
import PhotoHeader from '../Components/PhotoHeader'
import { faBuilding, faBusinessTime, faHandshakeAngle, faClipboardUser, faDollar, faReceipt, faStar, faClock, faDatabase, faWindowMaximize, faPeace } from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import about1 from '../Images/About/about1.jpg'
import about2 from '../Images/About/about2.jpg'
import about3 from '../Images/About/about3.jpg'
import about4 from '../Images/About/about4.jpg'
import about6 from '../Images/About/about6.png'
import about7 from '../Images/About/about7.png'
import about8 from '../Images/About/about8.png'
import about9 from '../Images/About/about9.png'
import about10 from '../Images/About/about10.png'
import about11 from '../Images/About/about11.png'
import about12 from '../Images/About/about12.png'
import about13 from '../Images/About/about13.png'
import about14 from '../Images/About/about14.png'
import about15 from '../Images/About/about15.png'
import about16 from '../Images/About/about16.png'
import ref1 from '../Images/References/ref1.jpeg'
import ref2 from '../Images/References/ref2.webp'
import ref3 from '../Images/References/ref3.jpeg'
import ref4 from '../Images/References/ref4.png'
import ref5 from '../Images/References/ref5.jpeg'

const About = () => {
  return (
    <>
    <PhotoHeader/>
    <h2 style={{bottom:"150px",position:"relative"}} className="text-center fw-bold "> About <span className='text-primary'>ONTARIO IT SOLUTION</span></h2>
    <p style={{bottom:"150px",position:"relative"}} className='text-center fw-bold fs-4'>Who we are & what we do</p>
    <section id='about-1' className='container mb-5' style={{marginTop:'-50px'}}>
        <div className='row'>
            <p className='text-warning fw-bold fs-5'>About YOU!</p>
            <div className='fw-bold fs-4'>
                <p>To Us, This Is All About You!</p>
                <p>You Want the Best Out of Your I.T. Environment.</p>
                <p>You’re Wanting Predictable Costs, Reliable Infrastructure, Friendly Support, Regular Reporting and Overall Peace of Mind!</p>
                <p className='text-end text-warning'>We look forward to making it happen for your Organization</p>
            </div>
            <hr className='my-5'/>
        </div>
    </section>
    <section id='about-2' className='container mb-5' >
        <p className='text-warning fw-bold fs-5 text-center mb-5'>Let's Introduce Ourselves</p>
        <div className="row">
            <div className="col-lg-6 my-3">
            <img src={about1} alt="about1" className='img-fluid' />
            </div>
            <div className="col-lg-6 my-3">
                <p className='text-warning fw-bold fs-5'>About ONTARIO IT SOLUTION</p>
                <div className='text-muted'>
                <p>We Help Your Organization Get ‘Peace of Mind’, Be More ‘Productive’ And ‘Responsive’.</p>
                <hr />
                <p>We’re A Vibrant, Innovative Team Based in Ontario, Providing Services Across Canada.</p>
                <p>We Love Giving World Class Friendly I.T. Support.</p>
                <hr/>
                <p>Our Team Has Been Providing I.T. Services for Over 25 Years and Have Extensive Experience in Installing, Configuring and Maintaining I.T. Infrastructures for Small and Medium Sized Businesses.</p>
                </div>
            </div>
        </div>
        <div className="row mt-5">
        <div className="col-lg-6 my-3">
                <p className='text-warning fw-bold fs-5'>WHY CHOOSE US?</p>
                <div className='text-muted'>
                <p>Clients Sized 5-500 Staff Partner With ONTARIO IT SOLUTION Because They Need Experienced I.T. Professionals To Deliver I.T. ‘Peace Of Mind’. Our Focus is On Real Support. For Real People.</p>
                <hr />
                <p>We Work With Our Clients’ to Understand Their Needs and Plan and Implement Solutions That Work for Their Organization, Both in The Short Term and Long Term.</p>
          
                <hr/>
                <p>As We Are Also A Small Business, We Offer You A More Personalized Service When You Are Dealing with Us.</p>
                </div>
            </div>
            <div className="col-lg-6 my-3">
            <img src={about2} alt="about2" className='img-fluid' />
            </div>
            <hr className='my-5'/>
        </div>
    </section>
    <section id='about-3' className='container mb-5' >
    <p className='text-warning fw-bold fs-5 text-center '>HOW ARE WE DIFFERENT?</p> 
    <p className='fw-bold fs-4 text-center mb-5'>I.T. Support & Services Powered by Innovation!</p> 
    <div className="row">
        <div className="col-lg-6 mt-5">
        <img src={about3} alt="about2" className='img-fluid' />
        </div>
        <div className="col-lg-6 mt-5">
        <p className='text-warning fw-bold fs-5'>Our I.T. Support Portal APP</p>
        <div className='text-muted'>
        <p> Presents Our Client-Centric App Which Increases Employee Productivity by Reducing Phone Support Time by Up To 50%!</p>
        <ul>
            <li>Easy to Use and Log Support Tickets (No Need to Login)</li>
            <li>Your Staff Get Instant Visibility to I.T. Support & Services</li>
            <li>Faster Access to Support So Staff Can Focus on What They Do Best</li>
            <li>Complete Visibility Via Our Web Portal (ONTARIO IT SOLUTION Central)</li>
            <li>Proactive Email Updates on All Work Performed (ONTARIO IT SOLUTION Desk)</li>
            <li className='text-decoration-underline'>Available on Windows, iOS, Android and Mac</li>
            <li className='fw-bold'>Included with All Our Support Plans!</li>
        </ul>
        <hr />
        <p className='text-center fw-bold text-warning'>Customized to Your Organization</p>
        </div>
        </div>
    </div>
    <div className="row mt-4">
        <div className="col-lg-5  mt-5 ms-3">
        <p className='text-warning fw-bold fs-5'>ONTARIO IT SOLUTION Suite</p>
        <div className='text-muted'>
        <p > Our Clients Are Provided Service and Support Through Our Innovative Suite of Tools;</p>
        <ul>
            <li className='text-dark fw-bold'>OIS <span className='fw-bold text-muted'>Monitor</span>
                <ul>
                    <li className='text-muted fw-normal'>Easy to Use and Log Support Tickets (No Need to Login)</li>
                </ul>
            </li>
        </ul>
        <ul>
            <li className='text-dark fw-bold'>OIS <span className='fw-bold text-muted'>Reports</span>
                <ul>
                    <li className='text-muted fw-normal'>Monthly Cloud, Network & Support Report</li>
                </ul>
            </li>
        </ul>
        <ul>
            <li className='text-dark fw-bold'>OIS <span className='fw-bold text-muted'>Review</span>
                <ul>
                    <li className='text-muted fw-normal'>Regularly Scheduled Network Audits & IT Reviews</li>
                </ul>
            </li>
        </ul>
        <ul>
            <li className='text-dark fw-bold'>OIS <span className='fw-bold text-muted'>Desk</span>
                <ul>
                    <li className='text-muted fw-normal'>Ticketing & Work Flow System</li>
                </ul>
            </li>
        </ul>
        <ul>
            <li className='text-dark fw-bold'>OIS <span className='fw-bold text-muted'>App</span>
                <ul>
                    <li className='text-muted fw-normal'>Desktop & Mobile Support Application</li>
                </ul>
            </li>
        </ul>
        <ul>
            <li className='text-dark fw-bold'>OIS <span className='fw-bold text-muted'>DB</span>
                <ul>
                    <li className='text-muted fw-normal'>Client Management Database</li>
                </ul>
            </li>
        </ul>
        <ul>
            <li className='text-dark fw-bold'>OIS <span className='fw-bold text-muted'>Central</span>
                <ul>
                    <li className='text-muted fw-normal'>Client Web Portal</li>
                </ul>
            </li>
        </ul>
        <ul>
            <li className='text-dark fw-bold'>OIS <span className='fw-bold text-muted'>Trends</span>
                <ul>
                    <li className='text-muted fw-normal'>Trend Analysis</li>
                </ul>
            </li>
        </ul>   
        
        </div>
        </div>
        <div className="col-lg-6 mt-5">
        <img src={about4} alt="about2" className='img-fluid' />
        </div>
        <hr className='mt-3'/>
    </div>
    </section>
    <section id='about-4' className='mb-5 pb-5 '>
    <div className="container"> 

    <h4 className='fw-bold  fs-1 p-5 text-center'>Our Fixed Fee Agreements</h4>
    <div className='row'>
      <div className="col-lg-3 my-5">
      <div className="card border border-3 border-secondary  w-100 h-100 text-center bg-transparent text-muted" style={{width: "18rem"}}>
              <div className="card-body px-3 py-5">
              <h5 className="card-title mb-4"><FontAwesomeIcon icon={faBusinessTime} size='2x'/></h5>
                <h6 className="card-title my-3 fw-bold">Unlimited Remote Helpdesk</h6>
                <p className="card-text">A Friendly Canadian Based Helpdesk Where You Can Call, Email, Or Use Our IT SUPPORT PORTAL APP for All Your I.T. Issues.</p>
              </div>
        </div>
      </div>
      <div className="col-lg-3 my-auto ">
        <img src={about6} alt="about6" className='img-fluid '/>
      </div>
      <div className="col-lg-3 my-5">
      <div className="card border border-3 border-secondary  w-100 h-100 text-center bg-transparent text-muted" style={{width: "18rem"}}>
              <div className="card-body px-3 py-5">
              <h5 className="card-title mb-4"><FontAwesomeIcon icon={faBuilding} size='2x'/></h5>
                <h6 className="card-title my-3 fw-bold">Onsite Support</h6>
                <p className="card-text">Friendly Onsite Personnel Available for Any I.T. Assistance You Might Need from New Computer Setup to Office I.T. Moves, to Hands On Support Needs.</p>
              </div>
        </div>
      </div>
      <div className="col-lg-3 my-auto ">
        <img src={about7} alt="about7" className='img-fluid '/>
      </div>
      
    </div>
    <div className='row'>
      <div className="col-lg-3 my-5">
      <div className="card border border-3 border-secondary  w-100 h-100 text-center bg-transparent text-muted" style={{width: "18rem"}}>
              <div className="card-body px-3 py-5">
                <h5 className="card-title mb-4"><FontAwesomeIcon icon={faHandshakeAngle} size='2x'/></h5>
                <h6 className="card-title my-3 fw-bold">Vendor Management</h6>
                <p className="card-text">We Can Act on Your Behalf with Your I.T. Vendors (eg. Software Vendors, Mobile Service Providers and More).</p>
              </div>
        </div>
      </div>
      <div className="col-lg-3 my-auto ">
        <img src={about8} alt="about7" className='img-fluid '/>
      </div>
      <div className="col-lg-3 my-5">
      <div className="card border border-3 border-secondary  w-100 h-100 text-center bg-transparent text-muted" style={{width: "18rem"}}>
              <div className="card-body px-3 py-5">
                <h5 className="card-title mb-4"><FontAwesomeIcon icon={faClipboardUser} size='2x'/></h5>
                <h6 className="card-title my-3 fw-bold">Regular CIO Meetings</h6>
                <p className="card-text">A Regular Scheduled Catch Up with Your Dedicated Chief Information Officer ``CIO`` to Budget and Plan for The Future.</p>
              </div>
        </div>
      </div>
      <div className="col-lg-3 my-auto ">
        <img src={about9} alt="about9" className='img-fluid '/>
      </div>
      
    </div>
    <div className='row'>
      <div className="col-lg-3 my-5">
      <div className="card border border-3 border-secondary  w-100 h-100 text-center bg-transparent text-muted" style={{width: "18rem"}}>
              <div className="card-body px-3 py-5">
                <h5 className="card-title mb-4"><FontAwesomeIcon icon={faDollar} size='2x'/></h5>
                <h6 className="card-title my-3 fw-bold">Fixed Fee Costs</h6>
                <p className="card-text">Imagine Being Able to Know Your I.T. Costs Well in Advance. Makes for Easy Budgeting, Right?</p>
              </div>
        </div>
      </div>
      <div className="col-lg-3 my-auto ">
        <img src={about10} alt="about10" className='img-fluid '/>
      </div>
      <div className="col-lg-3 my-5">
      <div className="card border border-3 border-secondary  w-100 h-100 text-center bg-transparent text-muted" style={{width: "18rem"}}>
              <div className="card-body px-3 py-5">
                <h5 className="card-title mb-4"><FontAwesomeIcon icon={faReceipt} size='2x'/></h5>
                <h6 className="card-title my-3 fw-bold">Executive Reporting</h6>
                <p className="card-text">You Will Receive A Monthly Easy to Read I.T. Executive Report with Metrics That Matter.</p>
              </div>
        </div>
      </div>
      <div className="col-lg-3 my-auto ">
        <img src={about11} alt="about11" className='img-fluid '/>
      </div>
      
    </div>
    <div className='row'>
      <div className="col-lg-3 my-5">
      <div className="card border border-3 border-secondary  w-100 h-100 text-center bg-transparent text-muted" style={{width: "18rem"}}>
              <div className="card-body px-3 py-5">
                <h5 className="card-title mb-4"><FontAwesomeIcon icon={faStar} size='2x'/></h5>
                <h6 className="card-title my-3 fw-bold">After Hours Support</h6>
                <p className="card-text">We Have 24/7/365 Support Available for Those Critical After-Hours Emergencies.</p>
              </div>
        </div>
      </div>
      <div className="col-lg-3 my-auto ">
        <img src={about12} alt="about12" className='img-fluid '/>
      </div>
      <div className="col-lg-3 my-5">
      <div className="card border border-3 border-secondary  w-100 h-100 text-center bg-transparent text-muted" style={{width: "18rem"}}>
              <div className="card-body px-3 py-5">
                <h5 className="card-title mb-4"><FontAwesomeIcon icon={faClock} size='2x'/></h5>
                <h6 className="card-title my-3 fw-bold">24/7/365 Monitoring</h6>
                <p className="card-text">Our Helpdesk Continually Monitors for Issues That Could Affect Your Organization and Proactively Works on Them.</p>
              </div>
        </div>
      </div>
      <div className="col-lg-3 my-auto ">
        <img src={about13} alt="about13" className='img-fluid '/>
      </div>
      
    </div>
    <div className='row'>
      <div className="col-lg-3 my-5">
      <div className="card border border-3 border-secondary  w-100 h-100 text-center bg-transparent text-muted" style={{width: "18rem"}}>
              <div className="card-body px-3 py-5">
                <h5 className="card-title mb-4"><FontAwesomeIcon icon={faDatabase} size='2x'/></h5>
                <h6 className="card-title my-3 fw-bold">Proactive Maintenance</h6>
                <p className="card-text">Keep Your I.T. Infrastructure & Services Up to Date with Microsoft, Adobe, Java and Other Updates Automatically Delivered.</p>
              </div>
        </div>
      </div>
      <div className="col-lg-3 my-auto ">
        <img src={about14} alt="about14" className='img-fluid '/>
      </div>
      <div className="col-lg-3 my-5">
      <div className="card border border-3 border-secondary  w-100 h-100 text-center bg-transparent text-muted" style={{width: "18rem"}}>
              <div className="card-body px-3 py-5">
                <h5 className="card-title mb-4"><FontAwesomeIcon icon={faWindowMaximize} size='2x'/></h5>
                <h6 className="card-title my-3 fw-bold">Central Access Portal</h6>
                <p className="card-text">A Web-Based Portal Where You and Your Staff Can Access Things Such as Open Tickets, Invoices, Agreements and More.</p>
              </div>
        </div>
      </div>
      <div className="col-lg-3 my-auto ">
        <img src={about15} alt="about15" className='img-fluid '/>
      </div>
      
    </div>
    <div className='row'>
      <div className="col-lg-6 my-5">
      <div className="card border border-3 border-secondary  w-100 h-100 text-center bg-transparent text-muted" style={{width: "18rem"}}>
              <div className="card-body py-3 px-5">
                <h5 className="card-title mb-4"><FontAwesomeIcon icon={faPeace} size='2x'/></h5>
                <h6 className="card-title my-3 fw-bold">Peace of Mind</h6>
                <p className="card-text">Finally, With All of The Above Working Together for Your Organization, You Will Have Peace of Mind That It Is Not One of The Worrying Parts of Your Business – That’s Our Number One Goal.</p>
              </div>
        </div>
      </div>
      <div className="col-lg-6 my-auto ">
        <img src={about16} alt="about16" className='img-fluid '/>
      </div>
     
      
    </div>
    </div>
    </section>
    <section id='about-5' className='mb-5 '>
        <div className="container">
        <h4 className='text-center fs-1 fw-bold'>FAQs</h4>
        <div className="row">
            <div className="col-lg-6 my-5">
                <p className='fw-bold text-warning'>What Is Covered Under Our Agreements?</p>
                <p className='text-muted'>As A Rule of Thumb, Everything Related to Keeping Your Existing I.T. Environment in Tip-Top Shape Is Covered.</p>
                <p className='fw-bold text-warning'>What Are the Standard Helpdesk Hours?</p>
                <p className='text-muted'>Our Helpdesk Is Available 8am – 5:30pm Monday To Friday Excluding Ontario Public Holidays.</p>
                <p className='fw-bold text-warning'>Can I Have Regular Onsite Visits?</p>
                <p className='text-muted'>Absolutely, In Fact We Encourage Every Client of Ours to Have Regular Visits. Most Productivity Affecting I.T. Issues Go Ignored Until the IT Person “Walks Past”.</p>
                <p className='fw-bold text-warning'>How Is Pricing Managed?</p>
                <p className='text-muted'>As Most of Our Pricing Is Based on A Per User Basis, We Work Out Each Month Whether There Is More or Less Staff and Modify the Agreement Accordingly.</p>
                <p className='fw-bold text-warning'>How Long Is the Agreement For?</p>
                <p className='text-muted'>Typically, Most Agreements Are 12 Months, However the General Rule of Thumb Is the Longer the Agreement, The Better the Deal (eg. For Each Extra Year Of An Agreement Over 12 Months, We Offer Discounts To The Entire Agreement).</p>
                <p className='fw-bold text-warning'>Do You Support Tablets and Phones?</p>
                <p className='text-muted'>Absolutely, We Live in An Age Where Everyone Is Connected All the Time and We Can Assist Your Team with This.</p>
            </div>
            <div className="col-lg-6 my-5">
            <p className='fw-bold text-warning'>Are Projects Covered in Our Agreement?</p>
            <p className='text-muted'>These Are Quoted Separately to Your Ongoing Monthly Agreement. BUT We do have Packages that includes Project Hours for the Contract Year.</p>
            <p className='fw-bold text-warning'>When Is After Hours Support Available?</p>
            <p className='text-muted'>Simple, 24x7x365. We May Not Be Able to Respond as Fast as We Can During Business Hours, We Will Always Aim to Do Our Best.</p>
            <p className='fw-bold text-warning'>What Technologies Do You Support?</p>
            <p className='text-muted'>We Have In-House Experience with Loads of Different Technologies (Microsoft, Apple, Google, And Much More).</p>
            <p className='fw-bold text-warning'>Is the Cloud Useful for My Business?</p>
            <p className='text-muted'>We Strongly Believe A “Hybrid Approach” Is Currently the Best Approach to Utilizing Cloud Technologies. A Mixture of Onsite and Offsite Infrastructure Works for Most Businesses.</p>
            <p className='fw-bold text-warning'>Can I Cancel My Agreement?</p>
            <p className='text-muted'>Absolutely, If We Break Any of The Conditions, We Promise to You, You Have Grounds to Cancel Your Agreement with Us and Walk Away.</p>
            <p className='fw-bold text-warning'>Do You Have Standard Forms We Can Use?</p>
            <p className='text-muted'>Absolutely, We Have A Vast Array of Computer and Email Policies, Privacy Policies and Other Forms. We Have A Full Suite of I.T. Documents, Processes & Procedures That Will Be Tailored to You Organization’s Needs.</p>
            
                 
            </div>
        </div>
        </div>
    </section>
    <section id='about-6' className='mb-5 bg-warning' >
        <div className="container pt-5">
            <h4 className='text-center fw-bold fs-1 text-white pt-5'>WE PARTNER WITH PROVEN INDUSTRY LEADERS</h4>
            <h5 className='text-center fw-bold fs-3 pb-5 '>Tried and true – we use these products ourselves.</h5>
         
        </div>
        <div className="container pb-5">
       <div className="row">
        <div className='text-center' >
        <img src={ref1} alt="" className='img-fluid p-4' style={{height:'200px', witdh:'200px'}} />
        <img src={ref2} alt="" className='img-fluid p-4' style={{height:'200px', witdh:'200px'}} />
        <img src={ref3} alt="" className='img-fluid p-4' style={{height:'200px', witdh:'200px'}} />
        <img src={ref4} alt="" className='img-fluid p-4' style={{height:'200px', witdh:'200px'}} />
        <img src={ref5} alt="" className='img-fluid p-4' style={{height:'200px', witdh:'200px'}} />
        
        </div>
        </div>
       </div>
    </section>
   
    <section id='about-7'>
    <div className='container'>
      <div className="row py-5">
        <div className="col-lg-4 text-center">
          <p className='fw-bold fs-3 text-muted'>Let's Talk</p>
          <p className='fw-bold fs-2 '>When You Are Ready, We Are Here.</p>
        </div>
        <div className="col-lg-4 d-flex align-content-center flex-wrap justify-content-center text-center">
          <p className='text-muted my-auto'>We Understand You Are Busy.  We Respect Your Time.  When You Are Ready, We Are Here.</p>
        </div>
        <div className="col-lg-4 d-flex align-content-center flex-wrap justify-content-center">
        <div className=' '>
          <Link to='/Contact' className="btn btn-outline-secondary btn-lg mt-3 text-dark border-3">Contact Us</Link>
        </div>
        </div>
      </div>
      </div>
    </section>
    </>
  )
}

export default About