import React from 'react'
import { Link } from 'react-router-dom'
import PhotoHeader from '../Components/PhotoHeader'
import Industry from '../Images/Industries.png'
import { faSuitcase, faPeopleCarryBox, faSchool, faLandmark, faLaptopMedical, faScaleBalanced, faClipboardUser, faH} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const Industries = () => {
  return (
    <>
     <PhotoHeader/>
    <h2 style={{bottom:"150px",position:"relative"}} className="text-center fw-bold "> INDUSTRY SOLUTIONS</h2>
    <p style={{bottom:"150px",position:"relative"}} className='text-center fw-bold fs-4'>Industries We Serve</p>
    <section id='industry-1' className='bg-light py-5' style={{marginTop:'-100px'}}>
     <div className="container">
     <div className="row py-5">
    <div className="col-lg-6">
    <img src={Industry} alt="Industries"  className='img-fluid'/>
    </div>    
    <div className="col-lg-6">
    <div className="card p-4 border-0 shadow-lg" >
    <div className="card-body">
    <h5 className="card-title fs-2 pb-4">Industry I.T. Solutions</h5>
    <p className="card-text">Organizations of All Industries and Sizes Trust ONTARIO IT SOLUTION To Help Them Make the Most of Their I.T. Investments. The Value Our Teams Are Able to Bring to The Table Is Accumulated in In-Depth Knowledge That Helps Us Drive Business Value Within Our Targeted Industries, As We Understand What It Is Like to Run an Organization in That Field. Across All of Our Industry Solutions, We Strive to Provide Clients with A Strategic and Proactive Methodology for I.T. Management.</p>
    </div>
    </div>
    
    </div>    
    </div>   
    <div className="row pt-5">
        <h4 className='fs-2 text-center fw-bold mb-5'>Industries We Serve</h4>
        <div className="col-lg-4">
            <div className="card border-0 w-100  p-4 text-center mb-5" style={{width: "18rem"}}>
              <div className="card-body">
              <h6 className="card-title text-warning mb-4"><FontAwesomeIcon icon={faPeopleCarryBox} size='2x'/></h6>
                <h5 className="card-title mb-2 ">Associations</h5>
                <p className="card-text text-muted">Unions, Committes, Agencies & Various Associations Reqiure Unique I.T Solutions</p>
                <div ><Link to='/Industries/Associations' className="btn btn-warning  me-3 ">Details</Link></div>
              </div>
            </div>
        </div>
        <div className="col-lg-4">
        <div className="card border-0 w-100   p-4 text-center mb-5" style={{width: "18rem"}}>
              <div className="card-body">
              <h6 className="card-title text-warning mb-4"><FontAwesomeIcon icon={faSchool} size='2x'/></h6>
                <h5 className="card-title mb-2 ">Education</h5>
                <p className="card-text text-muted">Technology Solutions That are Just as Reliable as Pencil and Paper</p>
                <div className='mt-5'><Link to='/Industries/Education' className="btn btn-warning  me-3 ">Details</Link></div>
              </div>
            </div>
        </div>
        <div className="col-lg-4">
        <div className="card border-0 w-100  p-4 text-center mb-5" style={{width: "18rem"}}>
              <div className="card-body">
              <h6 className="card-title text-warning mb-4"><FontAwesomeIcon icon={faLandmark} size='2x'/></h6>
                <h5 className="card-title mb-2 ">Financial Services</h5>
                <p className="card-text text-muted">Secure & Reliable I.T Solutions for Financial & Insurance Organizations</p>
                <div className='mt-5' ><Link to='/Industries/Financial' className="btn btn-warning  me-3 ">Details</Link></div>
              </div>
            </div>
        </div>
    </div>
    <div className="row ">
        <div className="col-lg-4">
            <div className="card border-0 w-100  p-4 text-center mb-5" style={{width: "18rem"}}>
              <div className="card-body">
              <h6 className="card-title text-warning mb-4"><FontAwesomeIcon icon={faLaptopMedical} size='2x'/></h6>
                <h5 className="card-title mb-2 ">Health Services Organizations</h5>
                <p className="card-text text-muted">I.T Services for Medical & Dental Offices, Hospitals, Clinics , Health Agencies & Community Health Groups.</p>
                <div ><Link to='/Industries/Health' className="btn btn-warning  me-3 ">Details</Link></div>
              </div>
            </div>
        </div>
        <div className="col-lg-4">
        <div className="card border-0 w-100   p-4 text-center mb-5" style={{width: "18rem"}}>
              <div className="card-body">
              <h6 className="card-title text-warning mb-4"><FontAwesomeIcon icon={faScaleBalanced} size='2x'/></h6>
                <h5 className="card-title mb-2 ">Legal</h5>
                <p className="card-text text-muted">I.T Solutions to Services & Protect Your Clients.</p>
                <div className='mt-5 pt-3' ><Link to='/Industries/Legal' className="btn btn-warning  me-3 ">Details</Link></div>
              </div>
            </div>
        </div>
        <div className="col-lg-4">
        <div className="card border-0 w-100  p-4 text-center mb-5" style={{width: "18rem"}}>
              <div className="card-body">
              <h6 className="card-title text-warning mb-4"><FontAwesomeIcon icon={faClipboardUser} size='2x'/></h6>
                <h5 className="card-title mb-2 ">Local Government</h5>
                <p className="card-text text-muted">Totally Aligned I.T Solutions for Local Government & Agencies</p>
                <div className='mt-5 pt-3'><Link to='/Industries/Local' className="btn btn-warning  me-3 ">Details</Link></div>
              </div>
            </div>
        </div>
    </div>
    <div className="row ">
        <div className="col-lg-4">
            <div className="card border-0 w-100  p-4 text-center mb-5" style={{width: "18rem"}}>
              <div className="card-body">
              <h6 className="card-title text-warning mb-4"><FontAwesomeIcon icon={faH} size='2x'/></h6>
                <h5 className="card-title mb-2 ">Non Profit</h5>
                <p className="card-text text-muted">Another Set of Hands Safeguard your Greatest Assets</p>
                <div ><Link to='/Industries/Profit' className="btn btn-warning  me-3 ">Details</Link></div>
              </div>
            </div>
        </div>
        <div className="col-lg-4">
        <div className="card border-0 w-100   p-4 text-center mb-5" style={{width: "18rem"}}>
              <div className="card-body">
              <h6 className="card-title text-warning mb-4"><FontAwesomeIcon icon={faSuitcase} size='2x'/></h6>
                <h5 className="card-title mb-2 ">Retail</h5>
                <p className="card-text text-muted">When You Focus on Your Customerrs, We Focus on Your I.T</p>
                <div ><Link to='/Industries/Retail' className="btn btn-warning  me-3 ">Details</Link></div>
              </div>
            </div>
        </div>
     
    </div>
    </div>   



    </section>
    <section className='bg-warning'>
        <div className='container'>
      <div className="row py-5">
        <div className="col-lg-4 text-center">
          <p className='fw-bold fs-3 text-muted'>Let's Talk</p>
          <p className='fw-bold fs-2 '>When You Are Ready, We Are Here.</p>
        </div>
        <div className="col-lg-4 d-flex align-content-center flex-wrap justify-content-center text-center">
          <p className='text-muted my-auto'>We Understand You Are Busy.  We Respect Your Time.  When You Are Ready, We Are Here.</p>
        </div>
        <div className="col-lg-4 d-flex align-content-center flex-wrap justify-content-center">
        <div className=' '>
          <Link to='/Contact' className="btn btn-outline-secondary btn-lg mt-3 text-dark border-3">Contact Us</Link>
        </div>
        </div>
      </div>
      </div>

      </section>
    </>
  )
}

export default Industries