import React from 'react'
import PhotoHeader from '../../Components/PhotoHeader'
import security from '../../Images/Services/security.png'
import Contact from '../Industry/contact'
import {  faArrowPointer, faServer, faCloud, faTruckMedical } from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const Security = () => {
  return (
    <>
    <PhotoHeader/>
    <h2 style={{bottom:"100px",position:"relative"}} className="text-center fw-bold  "> SECURITY & BUSINESS CONTINUITY </h2>
    <section id='IT1'>
    <div className="container">
    <div className="row my-5">
        <div className="col-lg-6">
            <img src={security} alt="security" className='img-fluid' />
        </div>
        <div className="col-lg-6">
            <h5 className='text-warning mt-4'>Security & Business Continuity</h5>
            <h4 className='fs-1 my-3'>It’s Not If It Happens, It’s When…</h4>
            <p className='my-3'> ONTARIO IT SOLUTION  Works With Your Organization to Limit Your Risk and Protect Your Organization. Cyber Security and Business Continuity are #1.</p>
            <p className='my-5 fw-bold text-muted'>We Make Security & Business Continuity…</p>
            <div className="text-muted text-end">
            <p ><span className='fw-bold  pe-2'>EASY</span> To Use</p>
            <p><span className='fw-bold  pe-2'>RELIABLE</span> To Use</p>
            <p><span className='fw-bold pe-2'>SAFE</span> To Use</p>
            <p><span className='fw-bold  pe-2'>AFFORDABLE</span> To Use</p>
            </div>
        </div>
    </div>
    </div>
    </section>
    <section id='IT2'>
    <div className="bg-light">
        <div className="container">
    <div className="row  py-5">
        <div className="col-lg-6">
            <div className='my-5'>
            <h5 className="card-title mb-4 text-warning"><FontAwesomeIcon icon={faArrowPointer} size='2x'/></h5>
                <h5 className='fw-bold fs-3'>Endpoint Security</h5>
                <p className='text-muted'>Ensuring Your Computers & Mobile Devices Are Secure is the New Normal. Let Us Show You How We Can Help.</p>
            </div>
            <div className='my-5'>
            <h5 className="card-title mb-4 text-warning"><FontAwesomeIcon icon={faServer} size='2x'/></h5>
                <h5 className='fw-bold fs-3'>Backup Solutions</h5>
                <p className='text-muted'>From Files to Servers, From Emails to Contract. When You Need It, We Are There to Bring It Back. Let Us Show You How.</p>
           
            </div>
        </div>
        <div className="col-lg-6">
        <div className='my-5'>
        <h5 className="card-title mb-4 text-warning"><FontAwesomeIcon icon={faCloud} size='2x'/></h5>
                <h5 className='fw-bold fs-3'>Cloud Security</h5>
                <p className='text-muted'>Being in The Cloud Should Not Concern You. We Know Cloud & Cloud Security. Let Us Show You How.</p>
            </div>
            <div className='my-5'>
            <h5 className="card-title mb-4 text-warning"><FontAwesomeIcon icon={faTruckMedical} size='2x'/></h5>
                <h5 className='fw-bold fs-3'>Business Continuity</h5>
                <p className='text-muted'>When Something Goes Wrong You Need to Be Back Up and Running Fast. Are You Confident Today You Will Be? Let Us Show You How.</p>
           
            </div>
        </div>
    </div>
    </div>
    </div>
    </section>
    <Contact/>
    </>
  )
}

export default Security