import React from 'react'
import PhotoHeader from '../../Components/PhotoHeader'
import cloud from '../../Images/Services/cloud.png'
import Contact from '../Industry/contact'
import { faSuitcase, faMobile, faPeopleCarryBox } from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faMicrosoft } from '@fortawesome/free-brands-svg-icons';


const Cloud = () => {
  return (
    <>
    <PhotoHeader/>
    <h2 style={{bottom:"100px",position:"relative"}} className="text-center fw-bold  "> CLOUD </h2>
    <section id='IT1'>
    <div className="container">
    <div className="row my-5">
        <div className="col-lg-6">
            <img src={cloud} alt="cloud" className='img-fluid' />
        </div>
        <div className="col-lg-6">
            <h5 className='text-warning mt-4'>Cloud Solutions Built For Your Business</h5>
            <h4 className='fs-1 my-3'>The Cloud Is Here. Embrace The Cloud.</h4>
            <p className='my-3'> ONTARIO IT SOLUTION  Helps Your Organization with Cloud Solutions Designed for You. When It Comes to Cloud Solutions, All Vendors Are Not the Same. There Are Different Models, Service Level Agreements, And Limitations.</p>
            <p className='my-5 fw-bold text-muted'>We Make Cloud Services…</p>
            <div className="text-muted text-end">
            <p ><span className='fw-bold  pe-2'>EASY</span> To Use</p>
            <p><span className='fw-bold  pe-2'>RELIABLE</span> To Use</p>
            <p><span className='fw-bold pe-2'>SAFE</span> To Use</p>
            <p><span className='fw-bold  pe-2'>AFFORDABLE</span> To Use</p>
            </div>
        </div>
    </div>
    </div>
    </section>
    <section id='IT2'>
    <div className="bg-light">
        <div className="container">
    <div className="row  py-5">
        <div className="col-lg-6">
            <div className='my-5'>
            <h5 className="card-title mb-4 text-warning"><FontAwesomeIcon icon={faMicrosoft} size='2x'/></h5>
                <h5 className='fw-bold fs-3'>Office 365 / Microsoft 365</h5>
                <p className='text-muted'>Office 365 Is A Cloud-Based Subscription Service That Brings Together the Best Tools for the Way People Work Today. Microsoft 365 Extends That Service to Your Mobile Device and Windows 10.</p>
            </div>
            <div className='my-5'>
            <h5 className="card-title mb-4 text-warning"><FontAwesomeIcon icon={faMobile} size='2x'/></h5>
                <h5 className='fw-bold fs-3'>Access Anywhere, Anytime</h5>
                <p className='text-muted'>From The Office, From The Client, From Home, From The Beach... Access To Your Business Services, From Anywhere, Anytime. We Can Help You Achieve it.</p>
           
            </div>
        </div>
        <div className="col-lg-6">
        <div className='my-5 pb-2'>
        <h5 className="card-title mb-4 text-warning"><FontAwesomeIcon icon={faSuitcase} size='2x'/></h5>
                <h5 className='fw-bold fs-3'>Your Business Apps In The Cloud</h5>
                <p className='text-muted'>Moving Your Line Of Business Apps to Cloud is Possible. Providing You The Freedom To Access These Services Like Your Office 365.</p>
            </div>
            <div className='my-5'>
            <h5 className="card-title mb-4 text-warning"><FontAwesomeIcon icon={faPeopleCarryBox} size='2x'/></h5>
                <h5 className='fw-bold fs-3'>Extend Access To Your Clients & Partners</h5>
                <p className='text-muted'>Being Able to Collaborate with Your Clients and Partners, When You Need to, Has Become Today’s New Normal. Let Us Show You How.</p>
           
            </div>
        </div>
    </div>
    </div>
    </div>
    </section>
  <Contact/>
    </>
  )
}

export default Cloud