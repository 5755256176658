import {React} from 'react'
import { Link, useLocation } from 'react-router-dom'
import logo from '../Images/IT.png'


const Navbar = () => {
  const location =useLocation()


  return (
    <>
    <nav  className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top p-3">
  <div className="container-fluid">
    <Link className="navbar-brand p-0 m-0" to="/"><img src={logo} alt="logo" width="50" height="50" class="d-inline-block  align-text-top" /></Link>
    <Link to='/' className='nav-link'><p className='my-auto text-white'> ONTARIO IT SOLUTION</p></Link>
    <button className="navbar-toggler" o  type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
   
    <div className="collapse navbar-collapse" id="navbarNavDropdown">
      <ul className="navbar-nav ms-auto me-4">
        <li className="nav-item">
          <Link className={location.pathname === '/' ? 'nav-link active-sp  ' : ' nav-link '}  to="/">Home</Link>
        </li>
        <li className="nav-item">
          <Link className={location.pathname === '/About' ? 'nav-link active-sp  ' : ' nav-link '}  to="/About">About</Link>
        </li>
        <li className="nav-item dropdown">
          <Link className={location.pathname === '/Services' ? 'nav-link active-sp  dropdown-toggle  ' : ' dropdown-toggle nav-link '}  to="/Services" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
             Services
          </Link>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <li><Link className={location.pathname === '/Services/IT' ?  'dropdown-item  active-sp  ' : ' dropdown-item  '}  to="/Services/IT">I.T Support</Link></li>
            <li><Link className={location.pathname === '/Services/ModernDesktop' ? 'dropdown-item  active-sp  ' : ' dropdown-item  '}  to="/Services/ModernDesktop">Modern Desktop</Link></li>
            <li><Link className={location.pathname === '/Services/Consulting" ' ? 'dropdown-item  active-sp  ' : ' dropdown-item  '}  to="/Services/Consulting">Consulting</Link></li>
            <li><Link className={location.pathname === '/Services/WorkFromHomeIT"' ? 'dropdown-item  active-sp  ' : ' dropdown-item  '}  to="/Services/WorkFromHome">Work From Home</Link></li>
            <li><Link className={location.pathname === '/Services/Cloud' ? 'dropdown-item  active-sp  ' : ' dropdown-item  '}  to="/Services/Cloud">Cloud</Link></li>
            <li><Link className={location.pathname === '/Services/Security' ? 'dropdown-item  active-sp  ' : ' dropdown-item  '}  to="/Services/Security">Security & Business Continuity</Link></li>
            <li><Link className={location.pathname === '/Services/FullService' ? 'dropdown-item  active-sp  ' : ' dropdown-item  '}  to="/Services/FullService">Full Service I.T Department </Link></li>
          </ul>
        </li>
        <li className="nav-item">
          <Link className={location.pathname === '/Industries' ? 'nav-link active-sp  ' : ' nav-link '}  to="/Industries">Industries</Link>
        </li>
        
        <li className="nav-item">
          <Link className={location.pathname === '/Contact' ? 'nav-link active-sp  ' : ' nav-link '}  to="/Contact">Contact</Link>
        </li>
        <li className="nav-item">
          <Link className={location.pathname === '/PrivacyPolicy' ? 'nav-link active-sp  ' : ' nav-link '}  to="/PrivacyPolicy">Privacy Policy</Link>
        </li>
      </ul>
    </div>
  </div>
    </nav>
    
    
    </>
  )
}

export default Navbar