import React from 'react'
import header from '../Images/header.jpg'


const PhotoHeader = () => {
  return (
    <><div style={{backgroundImage:`url(${header})`, height:'300px', backgroundSize:'cover', opacity:'0.4', backgroundPosition:'center'}}></div></>
  )
}

export default PhotoHeader