import React from 'react'
import PhotoHeader from '../../Components/PhotoHeader'
import service from '../../Images/Services/service.png'
import Contact from '../Industry/contact'
import {  faCloud,  faHeadphones, faSitemap, faPeopleGroup } from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const Service = () => {
  return (
    <>
    <PhotoHeader/>
    <h2 style={{bottom:"100px",position:"relative"}} className="text-center fw-bold  "> FULL SERVICE I.T. DEPARTMENT</h2>
    <section id='IT1'>
    <div className="container">
    <div className="row my-5">
        <div className="col-lg-6">
            <img src={service} alt="service" className='img-fluid' />
        </div>
        <div className="col-lg-6">
            <h5 className='text-warning mt-4'>Full Service I.T. Department</h5>
            <h4 className='fs-1 my-3'>The Power of a Big Business I.T. Department working For Your Organization.</h4>
            <p className='my-3'> ONTARIO IT SOLUTION  Provides Your Organization An All-In-One Full Service I.T. Department.  From I.T. Support to Cloud Services to I.T. Security to Your Own CIO!</p>
            <p className='my-5 fw-bold text-muted'>We Make Your I.T. Department…</p>
            <div className="text-muted text-end">
            <p ><span className='fw-bold  pe-2'>EASY</span> To Use</p>
            <p><span className='fw-bold  pe-2'>RELIABLE</span> To Use</p>
            <p><span className='fw-bold pe-2'>SAFE</span> To Use</p>
            <p><span className='fw-bold  pe-2'>AFFORDABLE</span> To Use</p>
            </div>
        </div>
    </div>
    </div>
    </section>
    <section id='IT2'>
    <div className="bg-light">
        <div className="container">
    <div className="row  py-5">
        <div className="col-lg-6">
            <div className='my-5'>
            <h5 className="card-title mb-4 text-warning"><FontAwesomeIcon icon={faHeadphones} size='2x'/></h5>
                <h5 className='fw-bold fs-3'>Dedicated I.T. Helpdesk & Tech Specialists</h5>
                <p className='text-muted'>No Matter Your Organization’s Size, You Get Your Own I.T. Helpdesk and Support Techs. You Get ONTARIO IT SOLUTION!</p>
            </div>
            <div className='my-5'>
            <h5 className="card-title mb-4 text-warning"><FontAwesomeIcon icon={faSitemap} size='2x'/></h5>
                <h5 className='fw-bold fs-3'>Dedicated Office I.T. Infrastructure</h5>
                <p className='text-muted'>No Matter Your Organization’s Size, You Get Your Own I.T. Infrastructure. You Get ONTARIO IT SOLUTION!</p>
           
            </div>
        </div>
        <div className="col-lg-6">
        <div className='my-5 pb-3'>
        <h5 className="card-title mb-4 text-warning"><FontAwesomeIcon icon={faPeopleGroup} size='2x'/></h5>
                <h5 className='fw-bold fs-3'>Dedicated CIO & I.T. Project Team</h5>
                <p className='text-muted'>No Matter Your Organization’s Size, You Get Your Own Team of I.T. Advisors. You Get ONTARIO IT SOLUTION!</p>
            </div>
            <div className='my-5'>
            <h5 className="card-title mb-4 text-warning"><FontAwesomeIcon icon={faCloud} size='2x'/></h5>
                <h5 className='fw-bold fs-3'>Dedicated Cloud Services</h5>
                <p className='text-muted'>No Matter Your Organization’s Size, You Get Your Own Place in The Cloud. You Get ONTARIO IT SOLUTION!</p>
           
            </div>
        </div>
    </div>
    </div>
    </div>
    </section>
    <Contact/>
    </>
  )
}

export default Service