import React from 'react'
import PhotoHeader from '../Components/PhotoHeader'
const Privacy = () => {
  return (
    <>
    <PhotoHeader/>
    <h2 style={{bottom:"100px",position:"relative"}} className="text-center fw-bold "> Privacy Policy   </h2>
    <section className='bg-light' style={{marginTop:'-50px'}}>
    <div className="container text-muted py-5 ">
    <p>The following discloses the information gathering and dissemination practices for ONTARIO IT SOLUTION. We reserve the right to modify the information contained herein at anytime without prior notice. If we do make changes to our privacy policies, we will modify this page accordingly.</p>
    <h6 className='fw-bold text-dark fs-3'>Information Collected From Normal Browsing</h6>
    <p>Like many websites, we collect certain information when you access our site. When you browse, read, or download information from this site, data is collected by us, including but not limited to your IP address, URL request, browser type, web pages visited, and date and time of your request. We use this information to generate statistics and measure site activity, so that we can improve the usefulness of the site to our visitors and for other business purposes. For example, we track which pages are most popular among visitors. Currently, we handle this information on an aggregated basis and do not use this information to identify any users.</p>
    <p>We often use analytics companies, such as Webtrends, Google Analytics and others, to assist us, and we may use additional or different service providers in the future.</p>
    <h6 className='fw-bold text-dark fs-3'>Information You Provide</h6>
    <p>At several places on the site, ONTARIO IT SOLUTION asks you to provide additional information so that we can respond to inquiries from you. For example, we may ask for your name, your e-mail address, and your location, or may ask for other information about you. We will use this information to contact you, administer mailing lists or online communities, or other activities related to the mission or services of ONTARIO IT SOLUTION. You do not have to provide us with this information to use many features of the site, but we will be unable to provide you with certain information unless you do so. We will manage and store this information as an ordinary business record. Should you wish to unsubscribe from receiving future communications from ONTARIO IT SOLUTION submit a request to be removed from our mailing list.</p>
    <h6 className='fw-bold text-dark fs-3'>Disclosure of Information</h6>
    <p>Available log records, account information, and all data stored on servers hosting this website, our blogs and any other online sites we use may be accessed by our system administrators who may make the information available to other ONTARIO IT SOLUTION personnel for business purposes.</p>
    <p>ONTARIO IT SOLUTION may share information that you have provided to us, including your name and email address, with our affiliated franchisors and with the companies that provide analytical services to us in support of the website. In some circumstances, we may share information that you have provided to one or more of our franchisees located in your area.</p>
    <p>In addition, we may share information that you have provided to us with non-affiliated third parties where we think doing so may allow you to receive information regarding products or and services that may be useful to you.</p>
    <p>Finally, we will provide information that you have given us when required by legal process to do so, such as by a subpoena.</p>
    <h6 className='fw-bold text-dark fs-3'>Cookies</h6>
    <p>Cookies are small computer files placed on your computer by our site. Our site may use both session and permanent cookies. Session cookies make it easier to use our site by, for example, enabling you to move through the site without having to reenter your personal information on each page. Session cookies are deleted automatically when you close your browser.</p>
    <p>In addition, our site also uses permanent cookies to facilitate our registration and login functions, we may also use cookies to recognize when you return to our website. In addition, some cookies may be set by advertising networks that provide advertising services on our website. In some instances, cookies set by those networks may trigger specific advertisements based on the parts of our site, and other websites, that your browser visits.</p>
    <h6 className='fw-bold text-dark fs-3'>Posting</h6>
    <p>Posting or updating content is a public action, and all content may be publicly visible. All content may be retained for restorative, archival, research or business purposes by ONTARIO IT SOLUTION.</p>
    <h6 className='fw-bold text-dark fs-3'>Security</h6>
    <p>ONTARIO IT SOLUTION employs reasonable administrative and technical security measures in place to help protect against loss, misuse and alteration of the information under our control. However, as with any Internet website, it is not possible to guarantee that the site will not be attacked or that information you provided may not be compromised.</p>
    <h6 className='fw-bold text-dark fs-3'>Other Websites</h6>
    <p>This website may contain links to independently run websites outside of the this one. ONTARIO IT SOLUTION is not responsible for the privacy practices or content of such websites. We encourage you to read the privacy policies of any websites you visit from this website and its accompanying blog.</p>
   
    
    
    
    </div>
    </section>
    </>
  )
}

export default Privacy