import React from 'react'
import { Link } from 'react-router-dom'
const contact = () => {
  return (
    <>
     <section>
        <div className="bg-warning">
            <div className="py-5 container">
                <p className='fw-bold fs-1 text-center'>Let's Talk</p>
                <Link to='/Contact' className='text-decoration-none'><button className='btn btn-outline-secondary d-block mx-auto btn-lg'>Contact Us</button></Link>
            </div>
        </div>
    </section>
    </>
  )
}

export default contact