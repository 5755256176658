import React from 'react'
import PhotoHeader from '../../Components/PhotoHeader'
import services1 from '../../Images/Services/services1.png'
import services2 from '../../Images/Services/services2.jpg'
import Contact from '../Industry/contact'
import { faRocket, faNetworkWired, faPeopleGroup, faSliders } from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const IT = () => {
  return (
    <>
    <PhotoHeader/>
    <h2 style={{bottom:"100px",position:"relative"}} className="text-center fw-bold  "> I.T. SUPPORT </h2>
    <section id='IT1'>
    <div className="container">
    <div className="row my-5">
        <div className="col-lg-6">
            <img src={services1} alt="services1" className='img-fluid' />
        </div>
        <div className="col-lg-6">
            <h5 className='text-warning mt-4'>I.T. Support (Managed Support)</h5>
            <h4 className='fs-1 my-3'>Our Managed Support Packages Provide the Support Our Clients Expect From Us.</h4>
            <p className='my-3'> ONTARIO IT SOLUTION Provides Proactive I.T. Support That Is Fully Aligned to Your Vision. We Are Your I.T. Department, Except That We Are A Bigger, More Skilled Team That Are Always Friendly!</p>
            <p className='my-5 fw-bold text-muted'>We Make I.T. Support…</p>
            <div className="text-muted text-end">
            <p ><span className='fw-bold  pe-2'>EASY</span> To Use</p>
            <p><span className='fw-bold  pe-2'>RELIABLE</span> To Use</p>
            <p><span className='fw-bold pe-2'>SAFE</span> To Use</p>
            <p><span className='fw-bold  pe-2'>AFFORDABLE</span> To Use</p>
            </div>
        </div>
    </div>
    </div>
    </section>
    <section id='IT2'>
    <div className="bg-light">
        <div className="container">
    <div className="row my-5 py-5">
        <div className="col-lg-6">
            <div className='my-5'>
                <h5 className="card-title mb-4 text-warning"><FontAwesomeIcon icon={faRocket} size='2x'/></h5>
                <h5 className='fw-bold fs-3'>Always Available Service Desk</h5>
                <p className='text-muted'>Our Team of Technical Staff Are Available 7/24 To Ensure Any Request Is Quickly Identified and Resolved. If Resolution Is Not Immediately Possible, They Will Escalate the Request to The Appropriate Resource to Get the Issue Taken Care of Promptly.</p>
            </div>
            <div className='my-5'>
            <h5 className="card-title mb-4 text-warning"><FontAwesomeIcon icon={faNetworkWired} size='2x'/></h5>
                <h5 className='fw-bold fs-3'>Supported by Local Experts</h5>
                <p className='text-muted'>Be Confident Knowing That Your Business Is Supported by a Highly Experienced & Trained Technical Team. Our Team of Solutions & Systems Specialists Will Ensure the Effectiveness and Reliability of Your I.T. Operations.</p>
           
            </div>
        </div>
        <div className="col-lg-6">
        <div className='my-5'>
        <h5 className="card-title mb-4 text-warning"><FontAwesomeIcon icon={faPeopleGroup} size='2x'/></h5>
                <h5 className='fw-bold fs-3'>On Site Support</h5>
                <p className='text-muted'>Our Team Is Available to Take on Any Daily Asks That May Come Up Where Onsite Support Is Needed. Tasks Can Be Requested for Immediate Response in The Case of An Emergency, Scheduled in Advance, Or Set Up as Recurring Request.</p>
            </div>
            <div className='my-5'>
            <h5 className="card-title mb-4 text-warning"><FontAwesomeIcon icon={faSliders} size='2x'/></h5>
                <h5 className='fw-bold fs-3'>One Point of Contact – ONTARIO IT SOLUTION Service Manager</h5>
                <p className='text-muted'>A Dedicated I.T. Service Manager Is A Single Point of Contact For All Your Day-To-Day I.T. Needs, Reporting, And Escalation.</p>
           
            </div>
        </div>
    </div>
    </div>
    </div>
    </section>
    <section id='IT3'>
    <div className='my-5 container'>
        <div className="row">
            <div className="col-lg-6">
                <h5 className='fw-bold text-warning fs-4'>Managed Support Plans</h5>   
                <h4 className='fw-bold fs-2 mt-4'>Your Organization Can Choose from 3 Customized Support Plans Designed to Deliver Peace Of Mind.</h4>
                <p className='my-5'>Additional Services & Features can be added to our Packagtes, Contact Us to find more out about our Silver, Gold And Platinum Packages.</p>
                <p> Here is a example of one of our Client Specific Customized Packaged List of Options.</p>
            </div>
            <div className="col-lg-6">
                <img src={services2} alt="services2" className='img-fluid'/>
            </div>
        </div>

    </div>
    </section>
   <Contact/>
    </>
  )
}

export default IT