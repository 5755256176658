import React from 'react'
import PhotoHeader from '../../Components/PhotoHeader'
import modern1 from '../../Images/Services/modern1.png'
import Contact from '../Industry/contact'
import {  faMobileScreenButton, faMobile, faLaptop, faUserCheck } from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const Modern = () => {
  return (
    <>
    <PhotoHeader/>
    <h2 style={{bottom:"100px",position:"relative"}} className="text-center fw-bold  "> MODERN DESKTOP </h2>
    <section id='IT1'>
    <div className="container">
    <div className="row my-5">
        <div className="col-lg-6">
            <img src={modern1} alt="modern1" className='img-fluid' />
        </div>
        <div className="col-lg-6">
            <h5 className='text-warning mt-4'>Modern Desktop</h5>
            <h4 className='fs-1 my-3'>The Modern Desktop is Mobile.</h4>
            <p className='my-3'> ONTARIO IT SOLUTION Understands Your Smartphones, Tablets and Computers Need to Be Able to Access Your organization’s Resources Anywhere, Anytime.</p>
            <p className='my-5 fw-bold text-muted'>We Make The Modern Mobile Desktop…</p>
            <div className="text-muted text-end">
            <p ><span className='fw-bold  pe-2'>EASY</span> To Use</p>
            <p><span className='fw-bold  pe-2'>RELIABLE</span> To Use</p>
            <p><span className='fw-bold pe-2'>SAFE</span> To Use</p>
            <p><span className='fw-bold  pe-2'>AFFORDABLE</span> To Use</p>
            </div>
        </div>
    </div>
    </div>
    </section>
    <section id='IT2'>
    <div className="bg-light">
        <div className="container">
    <div className="row  py-5">
        <div className="col-lg-6">
            <div className='my-5'>
            <h5 className="card-title mb-4 text-warning"><FontAwesomeIcon icon={faMobileScreenButton} size='2x'/></h5>
                <h5 className='fw-bold fs-3'>Smartphones & Tablets</h5>
                <p className='text-muted'>iPhone, iPad & Android Mobile Devices Are Part of The Tools of Doing Business Today. Let Us Help You Make Them A Seamless Part of Your Organization.</p>
            </div>
            <div className='my-5'>
            <h5 className="card-title mb-4 text-warning"><FontAwesomeIcon icon={faMobile} size='2x'/></h5>
                <h5 className='fw-bold fs-3'>Bring Your Own Device (BYOD)</h5>
                <p className='text-muted'>Ensuring Your Data and Services Are Safe Doesn’t Mean Needing to Buy Corporate Mobile Devices. Learn How the BYOD Can Be as Safe and Secure as Corporate Owned Devices.</p>
           
            </div>
        </div>
        <div className="col-lg-6">
        <div className='my-5 pb-2  '>
        <h5 className="card-title mb-4 text-warning"><FontAwesomeIcon icon={faLaptop} size='2x'/></h5>
                <h5 className='fw-bold fs-3'>Modern Computers</h5>
                <p className='text-muted'>Windows 10 Is Microsoft’s Most Reliable & Secure Operating System. Works Seamlessly in The Cloud. Let’s Get It Working Seamlessly for You.</p>
            </div>
            <div className='my-5'>
            <h5 className="card-title mb-4 text-warning"><FontAwesomeIcon icon={faUserCheck} size='2x'/></h5>
                <h5 className='fw-bold fs-3'>The Modern User</h5>
                <p className='text-muted'>When You Don’t Have to Worry About the How, Why, When, & Where of I.T., You ARE the Modern User! Let Us Show You How.</p>
           
            </div>
        </div>
    </div>
    </div>
    </div>
    </section>
   <Contact/>
    </>
  )
}

export default Modern